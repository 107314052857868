/*
* PROPS
* reverse: Boolean 
*/
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { Col, Row } from 'react-bootstrap';
import ButtonApplyNow from "../layout/buttonapplynow";
import "./workteam.scss";

const WorkTeam = ({ reverse = false }) => {

    const { t, i18n } = useTranslation()
    return (
        <section className="section-work-team">
            <Row className={reverse ? "flex-reverse" : ""}>
                <Col className={i18n.language.toLowerCase() === "es" ? "col-img es-bg" : "col-img"} md={6}>

                </Col>
                <Col className="col-text" md={6}>
                    <div className="wrapper-text">
                        <h4 className="header-square green md-blue lg-large">{t("work in a team to build a real product")}</h4>
                        <p>
                            {t("It's a part of our curriculum that gives you hands-on experience")}
                        </p>
                        <ul>
                            <li>{t("Build a real product")}</li>
                            <li>{t("Collaborate with students with complementary skills")}</li>
                            <li>{t("Add real work to your portfolio")}</li>
                            <li>{t("Participate in stand-ups and code reviews")}</li>
                            <li>{t("Gain experience: Labs is your first job")}</li>
                        </ul>
                        <ButtonApplyNow title={[t("Apply to Join"), t("BTS Internship Program")]} formVariant="signme" className="d-none d-lg-block apply-now" arrow={false}>{t("Apply Now")}</ButtonApplyNow>
                    </div>
                </Col>
            </Row>
        </section>
    )
}

export default WorkTeam
